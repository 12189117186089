import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../../components';
import {
    HeroSection,
    InfoSection,
    GallerySection,
    NextProjectSection,
} from './sections';

import styles from './Project.module.css';

export const Project = ({ pageContext, location }) => {
    const {
        year,
        title,
        photos,
        status,
        client,
        nextProject,
        description,
        formattedIndex,
        smallDescription,
        formattedProjectsLength,
        location: projectLocation,
    } = pageContext;

    const seoData = {
        description: smallDescription,
        image: `${photos[0].url}`,
        title: `${title} - Agostinho & João Pinto, Lda`,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }
            headerVariant="transparent">
            <HeroSection
                title={ title }
                image={ photos[0] }
                index={ formattedIndex }
                className={ styles.hero }
                location={ projectLocation }
                length={ formattedProjectsLength } />
            <InfoSection
                year={ year }
                client={ client }
                status={ status }
                text={ description }
                className={ styles.info }
                location={ projectLocation } />
            <GallerySection
                photos={ photos }
                id={ `${title}-${year}` }
                className={ styles.gallery } />
            <NextProjectSection
                data={ nextProject } />
        </Layout>
    );
};

Project.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired, // Injected on page creation (gatsby-node.js)
};

export default Project;
