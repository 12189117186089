import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ContentfulImage, Observer } from '../../../../components';

import styles from './Gallery.module.css';

export const Gallery = ({
    id,
    photos,
    className,
}) => {
    const sectionClasses = classNames(styles.section, className);

    const renderedPhotos = photos.map((photo, index) => (
        <Observer
            threshold={ 0.1 }
            key={ `${id}-${index}` }>
            { (isVisible) => (
                <div className={ classNames(styles.photoWrapper, { [styles.visible]: isVisible }) }>
                    <ContentfulImage
                        alt={ photo.alt }
                        src={ photo.url }
                        className={ styles.photo } />
                </div>
            ) }
        </Observer>
    ));

    return (
        <section className={ sectionClasses }>
            { renderedPhotos }
        </section>
    );
};

Gallery.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    photos: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    })).isRequired,
};

export default Gallery;
