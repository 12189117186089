import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    TitleAnimation,
    ContentfulImage,
    ScaleAndFadeAnimation,
} from '../../../../components';
import { useReady } from '../../../../hooks';

import styles from './Hero.module.css';

export const Hero = ({
    index,
    title,
    image,
    length,
    location,
    className,
}) => {
    const isReady = useReady();

    const sectionClasses = classNames(styles.section, className);

    return (
        <section className={ sectionClasses }>
            <ContentfulImage
                alt={ image.alt }
                src={ image.url }
                className={ styles.coverImage } />
            <span className={ styles.overlay } />
            <div className={ styles.content }>
                <ScaleAndFadeAnimation startAnimation={ isReady }>
                    <p className={ styles.location }>{ location }</p>
                </ScaleAndFadeAnimation>
                <div className={ styles.bottomContainer }>
                    <TitleAnimation startAnimation={ isReady }>
                        <h1 className={ styles.heading }>{ title }</h1>
                    </TitleAnimation>
                    <ScaleAndFadeAnimation startAnimation={ isReady }>
                        <p className={ styles.counter }>
                            { `${index}/` }
                            <span className={ styles.length }>{ length }</span>
                        </p>
                    </ScaleAndFadeAnimation>
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = {
    className: PropTypes.string,
    index: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default Hero;
