import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { useTranslations } from '../../../../hooks';
import {
    Observer,
    SplittedButton,
    TitleAnimation,
    ContentfulImage,
    ScaleAndFadeAnimation,
} from '../../../../components';

import styles from './NextProject.module.css';

export const NextProject = ({
    className,
    data: {
        link,
        photo,
        title,
        location,
    },
}) => {
    const { next } = useTranslations();

    const sectionClasses = classNames(styles.section, className);

    return (
        <Observer threshold={ 0.5 }>
            { (isVisible) => (
                <section className={ classNames(sectionClasses, { [styles.visible]: isVisible }) }>
                    <Link to={ link }>
                        <div className={ styles.container }>
                            <ContentfulImage
                                alt={ photo.alt }
                                src={ photo.url }
                                className={ styles.image } />
                            <span className={ styles.overlay } />
                        </div>
                        <div className={ styles.info }>
                            <div className={ styles.top }>
                                <ScaleAndFadeAnimation startAnimation={ isVisible }>
                                    <p className={ styles.location }>{ location }</p>
                                </ScaleAndFadeAnimation>
                            </div>
                            <div className={ styles.bottom }>
                                <TitleAnimation startAnimation={ isVisible }>
                                    <p className={ styles.title }>{ title }</p>
                                </TitleAnimation>
                            </div>
                        </div>
                        <SplittedButton
                            className={ styles.nextButton }
                            separatorClassName={ styles.buttonSeparator }>
                            { next }
                        </SplittedButton>
                    </Link>
                </section>
            ) }
        </Observer>
    );
};

NextProject.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        photo: PropTypes.shape({
            alt: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default NextProject;
