import React from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '../../../../hooks';
import { InfoBlock } from '../../../../components';

import styles from './Info.module.css';

export const Info = ({
    year,
    text,
    client,
    status,
    location,
    className,
}) => {
    const translations = useTranslations();

    return (
        <section className={ className }>
            <div className={ styles.infoBlocks }>
                <InfoBlock title={ translations.client } text={ client } className={ styles.block } />
                <InfoBlock title={ translations.location } text={ location } className={ styles.block } />
                <InfoBlock title={ translations.status } text={ status } className={ styles.block } />
                <InfoBlock title={ translations.year } text={ year } className={ styles.block } />
            </div>
            <p className={ styles.description }>{ text }</p>
        </section>
    );
};

Info.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
};

export default Info;
